<template>
  <div id="bookings-requests-list">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-row>
              <b-col md="6" sm="12">
                <b-form-group label="Proyectos" label-for="projects">
                  <v-select
                    v-model="filtersForm.project"
                    label="name"
                    placeholder="Selecciona un Proyecto"
                    :options="projects"
                    :reduce="projects => projects.id"
                  >
                    <span slot="no-options"> No se encontraron opciones </span>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12">
                <b-form-group label="Estado" label-for="status">
                  <v-select
                    v-model="filtersForm.status"
                    label="name"
                    placeholder="Selecciona un estado"
                    :options="status"
                    :reduce="status => status.id"
                  >
                    <span slot="no-options"> No se encontraron opciones </span>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-overlay
              :show="overlayList"
              opacity="1.0"
              spinner-small
              spinner-variant="primary"
              @hidden="_onHidden()"
            >
              <div class="d-flex align-items-end justify-content-end">
                <b-overlay
                  :show="overlayForm"
                  opacity="0.5"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                  @hidden="_onHidden()"
                >
                  <b-button
                    ref="button"
                    variant="primary"
                    :disabled="overlayForm"
                    @click.prevent="_exportBookingsOrders()"
                    v-if="permissions_visible.download_booking_order"
                  >
                    <feather-icon icon="DownloadIcon" class="mr-50" />
                    <span class="align-middle">
                      Descargar Pagos de Reservas
                    </span>
                  </b-button>
                </b-overlay>
              </div>
              <div class="mt-2">
                <b-table
                  hover
                  striped
                  show-empty
                  responsive="xl"
                  ref="refBookingsRequestListTable"
                  class="position-relative mb-1"
                  :items="bookingsOrders.data"
                  :fields="tableColumns"
                  primary-key="id"
                  empty-text="Datos no Disponibles"
                >
                  <template #cell(created_at)="data">
                    {{ data.item.created_at | moment('DD-MM-YYYY') }}
                  </template>
                  <template #cell(status)="data">
                    <b-badge pill :variant="_getStatusColor(data.item.status)">
                      {{ _getStatus(data.item.status) }}
                    </b-badge>
                  </template>
                  <template #cell(bookingrequest.quotation.project.name)="data">
                    {{
                      data.item.bookingrequest.property.project != null
                        ? data.item.bookingrequest.property.project.name
                        : '-'
                    }}
                  </template>
                  <template #cell(bookingrequest.contactBooking.name)="data">
                    {{ data.item.bookingrequest.contactBooking.name }}
                  </template>
                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >
                      <template #button-content>
                        <feather-icon
                          size="16"
                          icon="MoreVerticalIcon"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item
                        @click.prevent="_handleSelected(data.item)"
                        v-if="permissions_visible.edit_booking_order"
                      >
                        <feather-icon icon="ArrowRightCircleIcon" />
                        <span class="align-middle ml-50">Detalles</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
                <b-pagination
                  align="right"
                  v-model="filtersForm.page"
                  hide-goto-end-buttons
                  :total-rows="bookingsOrders.total"
                />
              </div>
            </b-overlay>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'BookingsOrdersList',
  data () {
    return {
      permissions_visible: {
        edit_booking_order: false,
        download_booking_order: false,
        audits_booking_order: false,
        show_bookingorder: false,
        delete_bookingorder: false
      },
      overlayList: false,
      overlayForm: false,
      currentPage: 1,
      totalPage: 0,
      status: [
        { id: 'canceled', name: 'Cancelada' },
        { id: 'pending', name: 'Pendiente' },
        { id: 'paid', name: 'Pagada' }
      ],
      filtersForm: {
        project: '',
        status: '',
        page: 1
      },
      tableColumns: [
        { key: 'bookingrequest.id', sortable: false, label: 'Código' },
        {
          key: 'bookingrequest.contactBooking.name',
          sortable: true,
          label: 'Nombre cliente'
        },
        {
          key: 'bookingrequest.contactBooking.email',
          sortable: true,
          label: 'Correo'
        },
        {
          key: 'bookingrequest.property.project.name',
          sortable: true,
          label: 'Proyecto'
        },
        { key: 'status', sortable: true, label: 'Estado' },
        { key: 'created_at', sortable: true, label: 'Fecha creación' },
        { key: 'actions', sortable: false, label: 'Acciones' }
      ]
    }
  },
  mounted () {
    this.permissions_visible = this.$PermissionHelper.checkPermissions(
      this.permissions_visible
    )
  },
  beforeMount () {
    this.filtersForm.page = this.currentPage
    this._fetchBookingsOrders(this.filtersForm)
  },
  watch: {
    filtersForm: {
      handler (filter) {
        this._fetchBookingsOrders(filter)
      },
      deep: true
    },
    currentPage (val) {
      this.filtersForm.page = this.currentPage
      this._fetchBookingsRequests(this.filtersForm)
    }
  },
  computed: {
    ...mapState('bookings', ['bookingsOrders']),
    ...mapGetters('profile', ['projects'])
  },
  methods: {
    ...mapActions('bookings', ['exportBookingsOrders', 'fetchBookingsOrders']),
    async _fetchBookingsOrders (filter) {
      this.overlayList = true
      await this.fetchBookingsOrders(filter).finally(() => {
        this.overlayList = false
      })
    },
    async _exportBookingsOrders () {
      this.overlayForm = true
      await this.exportBookingsOrders(this.filtersForm)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha enviado un correo con el archivo',
              icon: 'ThumbsUpIcon',
              variant: 'success'
            }
          })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al exportar los datos',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        })
        .finally(() => {
          this.overlayForm = false
        })
    },
    _getStatusColor (status) {
      switch (status) {
        case 'paid':
          return 'light-success'
        case 'canceled':
          return 'light-danger'
        case 'pending':
          return 'light-warning'
        case 'done':
          return 'light-success'
        default:
          return 'primary'
      }
    },
    _getStatus (status) {
      switch (status) {
        case 'paid':
          return 'Pagada'
        case 'canceled':
          return 'Cancelada'
        case 'pending':
          return 'Pendiente'
        case 'done':
          return 'Pagada'
        default:
          return status
      }
    },
    _handleSelected (data) {
      this.$router.push({
        name: 'bookings-orders-details',
        params: { id: data.id, bookings: data }
      })
    },
    _onHidden () {
      this.$refs.button.focus()
    }
  }
}
</script>
