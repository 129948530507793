<template>
  <div id="bookings-orders">
    <bookings-orders-list></bookings-orders-list>
  </div>
</template>

<script>
  import BookingsOrdersList from '../bookings/components/BookingsOrdersList'

  export default{
    name: 'BookingsOrdersView',
    components: {BookingsOrdersList}
  }
</script>
